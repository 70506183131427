import { RJSFSchema, UiSchema } from "@rjsf/utils";
import { customizeValidator } from "@rjsf/validator-ajv8";
import JSONSchemaForm from "@rjsf/core";
import { useTranslation } from "react-i18next";
import { MouseEventHandler, useContext, useEffect, useState } from "react";
import customFormats from "@beeldit/core/custom-validations/custom-validation";
import useProcessSchema from "@beeldit/core/hooks/useProcessSchema";
import widgets from "@beeldit/core/widgets/widgets";
import EventSessionSelector from "@main/event-sessions/components/EventSessionSelector";
import useGetEventSessionPrices from "@main/event-sessions/uses/useGetEventSessionPrices";
import { EventSession } from "@main/event-sessions/models/EventSession";
import StatusSelector from "@beeldit/statuses-and-actions/components/StatusSelector";

interface Prop {
  formConfig: {
    formRef: any;
    onSubmit: any;
    context: any;
    className?: string;
    filterData?: {
      event_id?: number;
    }
  };
}
interface Context {
  element: any;
  setElement: (element: any) => MouseEventHandler;
  backendFormErrors: any;
}

function BookingForm(props: Prop) {
  const { formRef, onSubmit, context, className, filterData } = props.formConfig;
  const { element, setElement, backendFormErrors } =
    useContext<Context>(context);

  const { t } = useTranslation();

  const [finalSchema, setFinalSchema] = useState<RJSFSchema>({});

  const translations = {
    event_session: t("event_session"),
    fiscal_name: t("fiscal_name"),
    fiscal_first_surname: t("fiscal_first_surname"),
    fiscal_last_surname: t("fiscal_last_surname"),
    fiscal_address: t("fiscal_address"),
    fiscal_number: t("fiscal_number"),
    fiscal_zip: t("fiscal_zip_code"),
    fiscal_city: t("fiscal_city"),
    fiscal_province: t("fiscal_province"),
    fiscal_country_code: t("fiscal_country_code"),
    phone: t("phone"),
    email: t("email"),
    tickets: t("tickets"),
    price_type: t("price_types"),
    price: t("price"),
    quantity: t("quantity"),
    invoice_account: t("invoice_account"),
    status: t("status"),
    total_price: t("total_price")
  };

  let schema: RJSFSchema = {
    type: "object",
    properties: {
      event_session_id: {
        type: "number",
        title: translations.event_session,
      },
      // Añadir status id tipo selector
      status_id: { // TODO - Hay que crear un selector de status filtrado por la clase
        type: "number",
        title: translations.status,                
      },  
      total_price: {
        type: "number",
        title: translations.total_price,
        readOnly: true,
        default: 0,
      },
      tickets: {
        type: "array",
        title: translations.tickets,
        items: {
          type: "object",
          title: "",
          properties: {            
            event_price_type_name: {
              type: "string",
              title: translations.price_type,
              readOnly: true,
            },
            event_rule_price: {
              type: "number",
              title: translations.price,
              readOnly: true,
            },
            quantity: {
              type: "number",
              title: translations.quantity,
              default: 0,
              minimum: 0,
            },
          },
        },
      },
      invoice_account: {
        type: "object",
        title: translations.invoice_account,
        properties: {
          fiscal_name: {
            type: "string",
            title: translations.fiscal_name,
          },
          fiscal_first_surname: {
            type: "string",
            title: translations.fiscal_first_surname,
          },
          fiscal_last_surname: {
            type: "string",
            title: translations.fiscal_last_surname,
          },
          fiscal_number: {
            type: "string",
            title: translations.fiscal_number,
          },
          fiscal_address: {
            type: "string",
            title: translations.fiscal_address,
          },
          fiscal_zip: {
            type: "string",
            title: translations.fiscal_zip,
          },
          fiscal_city: {
            type: "string",
            title: translations.fiscal_city,
          },
          fiscal_province: {
            type: "string",
            title: translations.fiscal_province,
          },
          fiscal_country_code: {
            type: "string",
            title: translations.fiscal_country_code,
            default: "ES",
            oneOf: [
              {
                const: "ES",
                title: "Spain",
              },
              {
                const: "IT",
                title: "Italy",
              },
              // TODO lo ideal es traerlos de una api como    https://restcountries.com/v3.1/all
            ],
          },
          phone: {
            type: "string",
            title: translations.phone,
          },
          email: {
            type: "string",
            title: translations.email,
          },
        },
      },      
    },
  };

  const uiSchema: UiSchema = {
    "ui:classNames": className,
    "ui:submitButtonOptions": {
      props: {
        disabled: false,
        className: "btn btn-info",
      },
      norender: true,
    },
    event_session_id: {
      "ui:widget": "event-session-selector",
      "ui:options": {
        filters: {
          event_id: filterData?.event_id,
        }
      }
    },
    tickets: {
      "ui:options": {
        addable: false, // quita el botón "Añadir"
        orderable: false, // quita los botones de subir/bajar
        removable: false // quita el botón "Borrar"
      },
    },
    status_id: {
      "ui:widget": "status-selector"
    }
  };
  const customWidgets = {
    ...widgets,
    ...{
      "event-session-selector": EventSessionSelector,
      "status-selector": StatusSelector,
    },
  };
  const handleChange = (type: any) => {
    /** Este método de momento es necesario aunque en realidad no tenga sentido, pero se debe a un bug de la libreria */
    // Actualizar el estado solo si no hay errores de validación
    setElement(type.formData);
    return console.log.bind(console, type);
  };
  const log = (type: any) => {
    return console.log.bind(console, type);
  };

  const formats = customFormats;
  const validator = customizeValidator({ customFormats: formats });

  const processSchemaFunction = useProcessSchema();

  useEffect(() => {
    setFinalSchema(processSchemaFunction(schema, element));
  }, [element]);

  /** INTERACCIÓN */

  const getEventSessionPrice = useGetEventSessionPrices();
  const onSuccessGetEventSessionPrice = (data: EventSession) => {
    // Por si es una edición, vamos a hacer un merge de los tickets que ya tiene el element. 
    //De manera que por cada prices de data, si en element hay un ticket con el mismo event_price_type_id, mantenemos el quantity
    // y si no, lo añadimos con quantity 0    
    const tickets = data.prices.map((price: any) => {
      return {
        event_price_type_id: price.event_price_type_id,
        event_price_type_name: price.event_price_type_name,
        event_rule_price: price.event_rule_price,
        quantity: element.tickets?.find((ticket: any) => ticket.event_price_type_id === price.event_price_type_id)?.quantity || 0,
      };
    });
    setElement({...element, tickets: tickets});
  };

  useEffect(() => {
    if (element.event_session_id) {
      getEventSessionPrice(
        element.event_session_id,
        onSuccessGetEventSessionPrice
      );      
    }
  }, [element.event_session_id]);

  useEffect(() => {
    let totalPrice = 0;
    if(element.tickets) {
      element.tickets.forEach((ticket: any) => {
        totalPrice += ticket.event_rule_price * ticket.quantity;
      });
    }
    setElement({...element, total_price: totalPrice});
  }, [element.tickets]);

  /** FIN INTERACCIÓN */

  return (
    <>
      <JSONSchemaForm
        noHtml5Validate
        showErrorList={false}
        ref={formRef}
        schema={finalSchema}
        uiSchema={uiSchema}
        widgets={customWidgets}
        formData={element}
        validator={validator}
        onChange={handleChange}
        onSubmit={onSubmit}
        onError={log("errors")}
        extraErrors={backendFormErrors}
      />
      { backendFormErrors ? 
        <div className="text-danger">{backendFormErrors['general']}</div>
        : null}
    </>
  );
}

export default BookingForm;
