import { createRef, useContext } from "react";
import JSONSchemaForm from "@rjsf/core";
import useGet from "./useGet";
import useUpdate from "./useUpdate";
import { DetailsActionContext } from "./DetailsActionContext";
import { useNavigate } from "react-router-dom";
import { TenantAndCommerceContext } from "@main/TenantAndCommerceContext";
import EventForm from "@main/events/components/EventForm";

function useDetailsAction(id: number): any {

  const navigate = useNavigate();

  const { tenantId, commerceId } = useContext(TenantAndCommerceContext);

  const detailsFunction = (id: any) => {
      navigate(`/panel/${tenantId}/${commerceId}/events/${id}`);
  };

  const get = useGet();
  const getFunction = (id: number) => get(id);

  /**
   * Form store success function definition
   */
  const onSuccessUpdate = () => {    
    getFunction(id); // We pass the function to refresh the table on success
  };

  const update = useUpdate();
  const updateFunction = () => update(onSuccessUpdate);
  
  /** Edition element definition */
  const { element } = useContext(DetailsActionContext);

  /**
   * Form Reference definition
   */
  const formRef = createRef<JSONSchemaForm>();  

  const onSubmitFunction = () => formRef.current?.formElement.current.requestSubmit() //We pass the function for the submit of the modal

  /**
   * Form configuration definition
   */
  const formConfig = {
    formRef: formRef, // We pass the ref to the form
    onSubmit: () => {
      updateFunction();
    },
    element: element, // We pass the element to the form in order to render it in the modal form
    context: DetailsActionContext,
  };

  const actionTemplate = (
    <EventForm formConfig={formConfig} />
  );

  /** END EDIT FORM ACTION */

  return {
    detailsFunction,
    getFunction,
    onSubmitFunction,
    actionTemplate,
  };
}

export default useDetailsAction;
