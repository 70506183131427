import { ObjectFieldTemplateProps } from "@rjsf/utils";
import { t } from "i18next";

function SliderTemplate(props: ObjectFieldTemplateProps, func: any) {
  return (
    <>
      <div className="section row">
        <h3>{t("slider")}</h3>
        <div className="col-12">
          <div className="property-wrapper">
            fasfasfasffasfasf{func("img", props)?.content}
          </div>
        </div>
        <div className="col-12">
          <div className="property-wrapper">
            {func("alt", props)?.content}
          </div>
        </div>
      </div>
    </>
  );
}

export default SliderTemplate;
