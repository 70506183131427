import { AuthContext } from "@beeldit/user-and-access/auth/AuthContext";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useContext, useEffect } from "react";
import { Outlet, useParams } from "react-router-dom";
import AppNavbar from "./AppNavbar";
import Sidebar from "./Sidebar";
import { addHeader } from "@beeldit/core/helpers/api";

import { TenantAndCommerceContext } from "@main/TenantAndCommerceContext";
import { t } from "i18next";
import {
  CalendarBlank,
  Gear,
  House,
  ArrowSquareUp,
  ArrowSquareDown,
  QrCode,
} from "@phosphor-icons/react";

import { getApi } from "@beeldit/core/helpers/api";
import useGetConfigurationAction from "@main/configurations/actions/get/useGetConfigurationAction";
import { ConfigurationsContext } from "@main/contexts/ConfigurationsContext";

function Panel() {
  const { tokenLoaded, user } = useContext(AuthContext);

  /** TODO - Encapsular en una logica aparte que además compruebe que estos parametros son numericos */
  const { setTenantId } = useContext(TenantAndCommerceContext);
  const { setCommerceId } = useContext(TenantAndCommerceContext);

  let { tenantId, commerceId } = useParams();

  if (!tenantId) {
    tenantId = "1";
  }
  setTenantId(parseInt(tenantId));
  getApi().defaults.headers.common["tenantId"] = tenantId;

  if (!commerceId) {
    commerceId = "1";
  }
  setCommerceId(parseInt(commerceId));
  getApi().defaults.headers.common["commerceId"] = commerceId;

  const { getConfigurationFunction } = useGetConfigurationAction();
  const { setLangs, setSchemas } = useContext(ConfigurationsContext);

  useEffect(() => {
    if (tokenLoaded) {
      getConfigurationFunction("langs", setLangs);
      getConfigurationFunction("schemas", setSchemas);
    }
  }, [tokenLoaded]);

  if (!tokenLoaded || !user) {
    return <div>Loading...</div>;
  }

  const items: any[] = [
    {
      label: t("events"),
      to: `/panel/${tenantId}/${commerceId}/events`,
      icon: <CalendarBlank size={24} />,
    },
    {
      label: t("commerce"),
      to: `/panel/${tenantId}/commerces/${commerceId}`,
      icon: <Gear size={24} />,
    },
    {
      label: t("pages"),
      to: `/panel/${tenantId}/${commerceId}/pages`,
      icon: <Gear size={24} />,
    },
    {
      label: t("configurations"),
      icon: <Gear size={24} />,
      children: [
        {
          label: t("header"),
          to: `/panel/${tenantId}/${commerceId}/header`,
          icon: <ArrowSquareUp size={24} />,
        },
        {
          label: t("footer"),
          to: `/panel/${tenantId}/${commerceId}/footer`,
          icon: <ArrowSquareDown size={24} />,
        },
      ],
    },
    {
      label: t("qr_scanner"),
      to: `/panel/${tenantId}/${commerceId}/qr-scanner`,
      icon: <QrCode size={24} />,
    },
  ];

  return (
    <div>
      <AppNavbar items={items} />
      <div className="container-fluid">
        <div className="row">
          <Sidebar items={items} />
          <div className="page-content p-4">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Panel;
