import { useContext, useEffect, useState } from "react";
import { AuthContext } from "@beeldit/user-and-access/auth/AuthContext";
import { t } from "i18next";
import { Button, Card, Tab, Tabs } from "react-bootstrap";
import { useParams } from "react-router-dom";
import useDetailsAction from "../actions/details/useDetailsAction";
import { RoomProvider } from "@main/rooms/contexts/RoomContext";
import AddressesPage from "@bloonde/location/addresses/pages/AddressesPage";
import { ListActionProvider as AddressListActionProvider } from "@bloonde/location/addresses/actions/list/ListActionContext";
import { CreateActionProvider as AddressCreateActionProvider } from "@bloonde/location/addresses/actions/create/CreateActionContext";
import { RemoveActionProvider as AddressRemoveActionProvider } from "@bloonde/location/addresses/actions/remove/RemoveActionContext";
import { EditActionProvider as AddressEditActionProvider } from "@bloonde/location/addresses/actions/edit/EditActionContext";
import { EventPriceTypeProvider } from "@main/event-price-types/contexts/EventPriceTypeContext";
import { ListActionProvider as RoomListActionProvider } from "@main/rooms/actions/list/ListActionContext";
import { CreateActionProvider as RoomCreateActionProvider } from "@main/rooms/actions/create/CreateActionContext";
import { RemoveActionProvider as RoomRemoveActionProvider } from "@main/rooms/actions/remove/RemoveActionContext";
import { EditActionProvider as RoomEditActionProvider } from "@main/rooms/actions/edit/EditActionContext";
import RoomsPage from "@main/rooms/pages/RoomsPage";
import { ListActionProvider as EventPriceTypeListActionProvider } from "@main/event-price-types/actions/list/ListActionContext";
import { CreateActionProvider as EventPriceTypeCreateActionProvider } from "@main/event-price-types/actions/create/CreateActionContext";
import { RemoveActionProvider as EventPriceTypeRemoveActionProvider } from "@main/event-price-types/actions/remove/RemoveActionContext";
import { EditActionProvider as EventPriceTypeEditActionProvider } from "@main/event-price-types/actions/edit/EditActionContext";
import EventPriceTypesPage from "@main/event-price-types/pages/EventPriceTypesPage";

function CommerceDashboardPage() {
  const { user } = useContext(AuthContext);

  let { commerceId } = useParams();

  if (!commerceId) {
    commerceId = "0";
  }

  const {
    getFunction,
    onSubmitFunction,
    actionTemplate: detailsActionTemplate,
  } = useDetailsAction(parseInt(commerceId));

  useEffect(() => {
    getFunction(commerceId);
  }, [commerceId]);

  /** Filtro con el que inicializamos las tablas asociadas al comercio en el que nos encontramos */
  const filters = { commerce_id: commerceId };
  const addressesFilters = { addressable_id: commerceId, addressable_type: 'App\\Models\\Commerce' };

  return (
    <>
      <div className="row">
        <div className="col-xl-6 col-12">
          <div className="mb-5">
            <Card>
              <Card.Header className="d-flex align-items-center justify-content-between ps-3 text-bg-primary">
                <span>{t("commerce")}</span>
              </Card.Header>

              <Card.Body>
                {detailsActionTemplate}
                <div className="text-end">
                  <Button variant="outline-primary" onClick={onSubmitFunction}>
                    {t("save_changes")}
                  </Button>
                </div>                
              </Card.Body>
            </Card>
          </div>
          <div className="mb-5">
            <AddressListActionProvider>
              <AddressRemoveActionProvider>
                <AddressCreateActionProvider>
                  <AddressEditActionProvider>
                    <AddressesPage filters={addressesFilters} />
                  </AddressEditActionProvider>
                </AddressCreateActionProvider>
              </AddressRemoveActionProvider>
            </AddressListActionProvider>
          </div>
        </div>
        <div className="col-xl-6 col-12">
          <div className="mb-5">
            <RoomListActionProvider>
              <RoomRemoveActionProvider>
                <RoomCreateActionProvider>
                  <RoomEditActionProvider>
                    <RoomsPage filters={filters} />
                  </RoomEditActionProvider>
                </RoomCreateActionProvider>
              </RoomRemoveActionProvider>
            </RoomListActionProvider>
          </div>
          <div className="mb-5">
            <EventPriceTypeListActionProvider>
              <EventPriceTypeRemoveActionProvider>
                <EventPriceTypeCreateActionProvider>
                  <EventPriceTypeEditActionProvider>
                    <EventPriceTypesPage filters={filters} />
                  </EventPriceTypeEditActionProvider>
                </EventPriceTypeCreateActionProvider>
              </EventPriceTypeRemoveActionProvider>
            </EventPriceTypeListActionProvider>
          </div>
        </div>
      </div>
    </>
  );
}

export default CommerceDashboardPage;
