import { RJSFSchema, UiSchema } from "@rjsf/utils";
import { customizeValidator } from "@rjsf/validator-ajv8";
import JSONSchemaForm from "@rjsf/core";
import { useTranslation } from "react-i18next";
import { EventPriceTypeContext } from "../contexts/EventPriceTypeContext";
import { MouseEventHandler, useContext, useEffect, useState } from "react";
import customFormats from "@beeldit/core/custom-validations/custom-validation";
import useProcessSchema from "@beeldit/core/hooks/useProcessSchema";
import widgets from "@beeldit/core/widgets/widgets";
import CommerceSelector from "@main/commerces/components/CommerceSelector";


interface Prop {
  formConfig: {
    formRef: any;
    onSubmit: any;
    context: any;
  };
}

interface Context {
  element: any;
  setElement: (element: any) => MouseEventHandler;
  backendFormErrors: any;
}

function EventPriceTypeForm(props: Prop) {
  const {formRef, onSubmit, context} = props.formConfig;    
  const { element, setElement, backendFormErrors } = useContext<Context>(context);

  const { t } = useTranslation();

  const [finalSchema, setFinalSchema] = useState<RJSFSchema>({});

  const translations = {
    name: t("name"),
    description: t("description"),
    percentage: t("percentage"),
  };

  let schema: RJSFSchema = {
    type: "object",
    // required: ['minutes', 'customer_id', 'task', 'date', 'report_id'],
    properties: {
      name: {
        type: "string",
        title: translations.name,
      },
      description: {
        type: "string",
        title: translations.description,
      },      
    },
  };
  const uiSchema: UiSchema = {
    "ui:submitButtonOptions": {
      props: {
        disabled: false,
        className: "btn btn-info",
      },
      norender: true,
    },
  };
  const customWidgets = {
    ...widgets,
  };
  const handleChange = (type: any) => {
    /** Este método de momento es necesario aunque en realidad no tenga sentido, pero se debe a un bug de la libreria */
    // Actualizar el estado solo si no hay errores de validación
    setElement(type.formData);
    return console.log.bind(console, type);
  };
  const log = (type: any) => {
    return console.log.bind(console, type);
  };

  const formats = customFormats;
  const validator = customizeValidator({ customFormats: formats });

  const processSchemaFunction = useProcessSchema();

  useEffect(() => {
    setFinalSchema(processSchemaFunction(schema, element));
  }, [element]);

  return (
    <JSONSchemaForm
      noHtml5Validate
      showErrorList={false}
      ref={formRef}
      schema={schema}
      uiSchema={uiSchema}
      widgets={customWidgets}
      formData={element}
      validator={validator}
      onChange={handleChange}
      onSubmit={onSubmit}
      onError={log("errors")}
      extraErrors={backendFormErrors}
    />
  );
}

export default EventPriceTypeForm;
