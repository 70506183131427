import { ArrayFieldTemplateProps, ObjectFieldTemplateProps } from "@rjsf/utils";

function DefaultArrayTemplate(props: ArrayFieldTemplateProps, className: string, id: string) {
  return (
    <div className={className}>
      {id != "root_sections" ? <h3>{props.title}</h3> : null}
      <div className="my-2">
        <div>
          {props.items.map((element, index) => {
            return (
              <>
                {id != "root_sections" ? <h4 className="element-title">{props.title} {index + 1} </h4> : null}
                <div className="d-flex">
                  <div className="flex-grow-1">{element.children}</div>
                  {props.canAdd && (
                    <div className="">
                      <button
                        className="btn btn-danger ms-4 ml-auto"
                        onClick={element.onDropIndexClick(index)}
                      >
                        -
                      </button>
                    </div>
                  )}
                </div>
              </>
            );
          })}
        </div>
        <div className="mt-2 d-flex justify-content-end">
          <div>
            {props.canAdd && (
              <button
                className="btn btn-primary ml-auto"
                onClick={props.onAddClick}
              >
                +
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DefaultArrayTemplate;
