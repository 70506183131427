import { RJSFSchema, UiSchema } from "@rjsf/utils";
import { customizeValidator } from "@rjsf/validator-ajv8";
import JSONSchemaForm from "@rjsf/core";
import { useTranslation } from "react-i18next";
import { EventSessionContext } from "../contexts/EventSessionContext";
import { useContext, useEffect, useState } from "react";
import customFormats from "@beeldit/core/custom-validations/custom-validation";
import useProcessSchema from "@beeldit/core/hooks/useProcessSchema";
import widgets from "@beeldit/core/widgets/widgets";
import EventSelector from "@main/events/components/EventSelector";
import EventRuleSelector from "@main/event-rules/components/EventRuleSelector";

interface Prop {
  formConfig: {
    formRef: any;
    onSubmit: any;
    context: any;
  };
}

interface Context {
  element: any;
  setElement: (element: any) => any;
  backendFormErrors: any;
}

function EventSessionForm(props: Prop) {
  const { formRef, onSubmit, context } = props.formConfig;
  const { element, setElement, backendFormErrors } =
    useContext<Context>(context);

  const { t } = useTranslation();

  const [finalSchema, setFinalSchema] = useState<RJSFSchema>({});

  const translations = {
    event: t("event"),
    event_rule: t("event_rule"),
    date: t("date"),
    time_start: t("time_start"),
    time_end: t("time_end"),
    prices: t("prices"),
    price_type: t("price_type"),
    price: t("price"),
  };

  let schema: RJSFSchema = {
    type: "object",
    // required: ['minutes', 'customer_id', 'task', 'date', 'report_id'],
    properties: {
      event_id: {
        type: "integer",
        title: translations.event,
      },
      event_rule_id: {
        type: "integer",
        title: translations.event_rule,
      },
      date: {
        type: "string",
        title: translations.date,
        format: "date",
        default: null,
      },
      time_start: {
        type: "string",
        title: translations.time_start,
        format: "time",
      },
      time_end: {
        type: "string",
        title: translations.time_end,
        format: "time",
      },
      prices: {
        type: "array",
        title: translations.prices,
        items: {
          type: "object",
          title: "",
          properties: {
            event_price_type_name: {
              type: "string",
              title: translations.price_type,
              readOnly: true,
            },
            event_rule_price: {
              type: "number",
              title: translations.price,
            },
          },
        },
      }
    },
  };
  const uiSchema: UiSchema = {
    "ui:submitButtonOptions": {
      props: {
        disabled: false,
        className: "btn btn-info",
      },
      norender: true,
    },
    event_id: {
      "ui:widget": "event-selector",
    },
    event_rule_id: {
      "ui:widget": "event-rule-selector",
    },
    prices: {
      "ui:options": {
        addable: false, // quita el botón "Añadir"
        orderable: false, // quita los botones de subir/bajar
        removable: false // quita el botón "Borrar"
      },
    }
  };
  const customWidgets = {
    ...widgets,
    ...{ "event-selector": EventSelector },
    ...{ "event-rule-selector": EventRuleSelector },
  };
  const handleChange = (type: any) => {
    /** Este método de momento es necesario aunque en realidad no tenga sentido, pero se debe a un bug de la libreria */
    // Actualizar el estado solo si no hay errores de validación
    setElement(type.formData);
    return console.log.bind(console, type);
  };
  const log = (type: any) => {
    return console.log.bind(console, type);
  };

  const formats = customFormats;
  const validator = customizeValidator({ customFormats: formats });

  const processSchemaFunction = useProcessSchema();

  useEffect(() => {
    setFinalSchema(processSchemaFunction(schema, element));
  }, [element]);

  return (
    <JSONSchemaForm
      noHtml5Validate
      showErrorList={false}
      ref={formRef}
      schema={schema}
      uiSchema={uiSchema}
      widgets={customWidgets}
      formData={element}
      validator={validator}
      onChange={handleChange}
      onSubmit={onSubmit}
      onError={log("errors")}
      extraErrors={backendFormErrors}
    />
  );
}

export default EventSessionForm;
