import { ObjectFieldTemplateProps } from "@rjsf/utils";
import ImgWysiwygLinkTemplate from "./sections/ImgWysiwygLinkTemplate";
import MainImgTemplate from "./sections/MainImgTemplate ";
import WysiwygTemplate from "./sections/WysiwygTemplate.";
import LocationTemplate from "./sections/LocationTemplate.";
import GalleryTemplate from "./sections/GalleryTemplate";
import SliderTemplate from "./sections/SliderTemplate";
import HouseSummaryTemplate from "./sections/HouseSummaryTemplate";
import HouseFeaturesTemplate from "./sections/HouseFeaturesTemplate";
import YouWillProbablyLikeTemplate from "./sections/YouWillProbablyLikeTemplate";
import { t } from "i18next";
import EventMain from "./sections/EventMain";

function PageFormTemplate(props: ObjectFieldTemplateProps) {
  let id = props.idSchema.$id;
  let type = null;

  if (
    props &&
    props.uiSchema &&
    props.uiSchema["ui:options"] &&
    props.uiSchema["ui:options"].id &&
    typeof props.uiSchema["ui:options"].id == "string"
  ) {
    id = props.uiSchema["ui:options"].id;
  }
  if (
    props &&
    props.uiSchema &&
    props.uiSchema["ui:options"] &&
    props.uiSchema["ui:options"].type &&
    typeof props.uiSchema["ui:options"].type == "string"
  ) {
    type = props.uiSchema["ui:options"].type;
  }
  return (
    <div>
      {id == "root" ? renderForm(props) : null}
      {id == "root_seo" ? renderSeo(props) : null}
      {type == "translatable" ? renderTranslatableForm(props) : null}
      {type ? renderElement(props, type) : null}
      {id.includes("content") ? renderContent(props) : null}
      {id.includes("sections") ? renderSection(props) : null}
    </div>
  );
}

function renderForm(props: ObjectFieldTemplateProps) {
  return (
    <>
      <div className="cms-section">
        <h3>{t("main_data")}</h3>
        <div className="row">
          <div className="col-6">
            <div className="property-wrapper">
              {getPropertyByName("title", props)?.content}
            </div>
            <div className="property-wrapper">
              {getPropertyByName("slug", props)?.content}
            </div>
            <div className="property-wrapper">
              {getPropertyByName("tag_ids", props)?.content}
            </div>
            <div className="property-wrapper">
              {getPropertyByName("status", props)?.content}
            </div>
          </div>
          <div className="col-6">
            <div className="property-wrapper">
              {getPropertyByName("pageable_id", props)?.content}
            </div>
          </div>
        </div>
      </div>
      <div className="cms-section">
        <h3>{t("seo_data")}</h3>
        <div className="row">{getPropertyByName("seo", props)?.content}</div>
      </div>
      <div className="row">{getPropertyByName("sections", props)?.content}</div>
    </>
  );
}

function renderSeo(props: ObjectFieldTemplateProps) {
  return (
    <>
      <div className="row">
        <div className="col-4">
          <div className="property-wrapper">
            {getPropertyByName("title", props)?.content}
          </div>
        </div>
        <div className="col-4">
          <div className="property-wrapper">
            {getPropertyByName("description", props)?.content}
          </div>
        </div>
        <div className="col-4">
          <div className="property-wrapper">
            {getPropertyByName("image", props)?.content}
          </div>
        </div>
      </div>
    </>
  );
}

function renderSection(props: ObjectFieldTemplateProps) {
  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="property-wrapper">
            {getPropertyByName("content", props)?.content}
          </div>
        </div>
      </div>
    </>
  );
}

function renderContent(props: ObjectFieldTemplateProps) {
  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="property-wrapper asfasf">
            {getPropertyByName("elements", props)?.content}
          </div>
        </div>
      </div>
    </>
  );
}

function renderElement(props: ObjectFieldTemplateProps, type: string) {
  if (type === "img_wysiwyg_link") {
    return ImgWysiwygLinkTemplate(props, getPropertyByName);
  } else if (type === "main_img") {
    return MainImgTemplate(props, getPropertyByName);
  } else if (type === "wysiwyg") {
    return WysiwygTemplate(props, getPropertyByName); // Tiene esta estructura porque es una sección única que no pertenece a un array
  } else if (type === "location") {
    return LocationTemplate(props, getPropertyByName); // Tiene esta estructura porque es una sección única que no pertenece a un array
  } else if (type === "gallery" || type === "gallery_with_main") {
    return GalleryTemplate(props, getPropertyByName);
  } else if (type === "slider") {
    return SliderTemplate(props, getPropertyByName);
    // TODO - ROADMAP - Esto debería estar fuera del paquete
  } else if (type === "house_summary") {
    return HouseSummaryTemplate(props, getPropertyByName);
  } else if (type === "house_features") {
    return HouseFeaturesTemplate(props, getPropertyByName);
  } else if (type === "you_will_probably_like") {
    return YouWillProbablyLikeTemplate(props, getPropertyByName);
  } else if (type === "event_main") {
    return EventMain(props, getPropertyByName);
  }
}

function renderTranslatableForm(props: ObjectFieldTemplateProps) {
  const langs = ["es", "en", "de"];
  let selectedLang = langs.find((lang) => {
    return (
      props.schema && props.schema.properties && props.schema.properties[lang]
    );
  });
  return selectedLang ? getPropertyByName(selectedLang, props)?.content : null;
}

function getPropertyByName(name: string, props: ObjectFieldTemplateProps) {
  let element = props.properties.find((element: any) => element.name == name);
  // console.log(element);
  return element;
}

export default PageFormTemplate;
