import { useContext, useEffect, useState } from "react";
import { t } from "i18next";
import { Button, Card } from "react-bootstrap";
import { useParams } from "react-router-dom";
import useDetailsByPageableAction from "../actions/detailsByPageable/useDetailsByPageableAction";

function PageEditionByPageablePage(props: {pageableId: number, pageableType: string}) {
  const { pageableId, pageableType } = props;

  const {
    getFunction,
    onSubmitFunction,
    actionTemplate: detailsActionTemplate,
  } = useDetailsByPageableAction(pageableId, pageableType);

  useEffect(() => {
    getFunction(pageableId, pageableType);
  }, [pageableId, pageableType]);

  return (
      <>
        <div className="row">
          <div className="col-12">
            <div className="mb-5">
              <Card>
                <Card.Header className="d-flex align-items-center justify-content-between ps-3 bg-white">
                  <span>{t("Page")}</span>
                </Card.Header>

                <Card.Body>
                  {detailsActionTemplate}
                  <div className="text-end">
                    <Button variant="outline-primary" onClick={onSubmitFunction}>
                      Save Changes
                    </Button>
                  </div>
                </Card.Body>
              </Card>
            </div>

          </div>
        </div>
      </>
  );
}

export default PageEditionByPageablePage;
