import { ArrayFieldTemplateProps } from "@rjsf/utils";
import { t } from "i18next";

function GalleryArrayTemplate(
  props: ArrayFieldTemplateProps,
  className: string,
  id: string
) {
  return (
    <div className={className}>
      {id != "root_sections" ? <h3>{props.title}</h3> : null}
      <div className="my-2">
        <div className="row">
          {props.items.map((element, index) => {
            return (
              <>
                <div className="col-4">
                  <div className="d-flex">
                    <div className="flex-grow-1">
                      {element.children}
                      {props.canAdd && (
                        <button
                          className="btn btn-danger mt-2 ml-auto"
                          onClick={element.onDropIndexClick(index)}
                        >
                          {t('remove')}
                        </button>
                    )}
                    </div>                    
                  </div>
                </div>
              </>
            );
          })}
          <div className="col-4 text-center">
            {props.canAdd && (
              <button
                className="btn btn-primary ml-auto"
                onClick={props.onAddClick}
              >
                +
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default GalleryArrayTemplate;
