import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {},
  },
  es: {
    translation: {
      sign_in: "Iniciar sesión",
      email: "Email",
      password: "Contraseña",
      events: "Eventos",
      event: "Evento",
      name: "Nombre",
      capacity: "Capacidad",
      date_start: "Fecha de inicio",
      date_end: "Fecha fin",
      event_type: "Tipo",
      Actions: "Acciones",
      all_rooms: "Todas las salas",
      all_types: "Todos los tipos",
      room: "Sala",
      description: "Description",
      close: "Cerrar",
      save_changes: "Guardar",
      create_event: "Crear evento",
      edit_event: "Editar evento",
      remove_event: "Borrar evento",
      "are_you_sure_you_want_to_remove_this_event?":
        "¿Estás seguro de que quieres borrar este evento?",
      generate_event_sessions: "Generar sesiones",
      "are_you_sure_you_want_to_generete_the_sessions_of_this_events?":
        "Las sesiones no bloqueadas comprendidas entre estas dos fechas se borrarán y se autogenerarán de nuevo ¿Estás seguro de que quieres continuar?",
      event_rules: "Reglas de evento",
      create_event_rule: "Crear regla de evento",
      edit_event_rule: "Editar regla de evento",
      remove_event_rule: "Borrar regla de evento",
      "are_you_sure_you_want_to_remove_this_event_rule?":
        "¿Estás seguro de que quieres borrar esta regla de evento?",
      weekday: "Día de la semana",
      special_date: "Día",
      time_start: "Hora de inicio",
      time_end: "Hora fin",
      prices: "Precio",
      event_sessions: "Sesiones de evento",
      create_event_session: "Crear sesión de evento",
      edit_event_session: "Editar sesión de evento",
      remove_event_session: "Borrar sesión de evento",
      "are_you_sure_you_want_to_remove_this_event_session?":
        "¿Estás seguro de que quieres borrar esta sesión de evento?",
      date: "Fecha",
      price_type: "Tipo de precio",
      price: "Precio",
      all_event_rules: "Todas las reglas de evento",
      event_rule: "Regla de evento",
      bookings: "Reservas",
      create_booking: "Crear reserva",
      edit_booking: "Editar reserva",
      remove_booking: "Borrar reserva",
      "are_you_sure_you_want_to_remove_this_booking?":
        "¿Estás seguro de que quieres borrar esta reserva?",
      event_session: "Sesión de evento",
      fiscal_name: "Nombre",
      fiscal_first_surname: "Primer apellido",
      fiscal_last_surname: "Segundo apellido",
      fiscal_address: "Dirección",
      fiscal_number: "DNI",
      fiscal_zip_code: "Código postal",
      fiscal_city: "Ciudad",
      fiscal_province: "Provincia",
      fiscal_country_code: "País",
      phone: "Teléfono",
      tickets: "Tickets",
      quantity: "Cantidad",
      invoice_account: "Datos de facturación",
      total_price: "Precio total",
      status: "Estado",
      commerces: "Comercios",
      commerce: "Comercio",
      create_commerce: "Crear comercio",
      edit_commerce: "Editar comercio",
      remove_commerce: "Borrar comercio",
      "are_you_sure_you_want_to_remove_this_commerce?":
        "¿Estás seguro de que quieres borrar este comercio?",
      published: "Publicado",
      all_tenants: "Todos los tenants",
      contact_email: "Email de contacto",
      survey_link: "Link para encuesta",
      rooms: "Salas",
      create_room: "Crear sala",
      edit_room: "Editar sala",
      remove_room: "Borrar sala",
      "are_you_sure_you_want_to_remove_this_room?":
        "¿Estás seguro de que quieres borrar este sala?",
      numbered_tickets: "Tickets numerados",
      addresses: "Direcciones",
      address: "Dirección",
      create_address: "Crear dirección",
      edit_address: "Editar dirección",
      remove_address: "Borrar dirección",
      "are_you_sure_you_want_to_remove_this_address?":
        "¿Estás seguro de que quieres borrar esta dirección?",
      street: "Calle",
      city: "Ciudad",
      country: "País",
      state: "Provincia",
      postal_code: "Código postal",
      geographic_longitude: "Longitud geográfica",
      geographic_latitude: "Latitud geográfica",
      google_maps_link: "Enlace de google map",
      event_price_types: "Tipos de precio de eventos",
      event_price_type: "Tipo de precio de evento",
      create_event_price_type: "Crear tipo de precio de evento",
      edit_event_price_type: "Editar tipo de precio de evento",
      remove_event_price_type: "Borrar tipo de precio de evento",
      "are_you_sure_you_want_to_remove_this_event_price_type?":
        "¿Estás seguro de que quieres borrar este tipo de precio de evento?",
      all_addresses: "Todas las direcciones",
      none: "Ninguno",
      sunday: "Domingo",
      monday: "Lunes",
      tuesday: "Martes",
      wednesday: "Miércoles",
      thursday: "Jueves",
      friday: "Viernes",
      saturday: "Sábado",
      lock_event_sessions_message: "¿Estás seguro de querer bloquear esta sesión?",
      unlock_event_sessions_message: "¿Estás seguro de querer desbloquear esta sesión?",
      qr_scanner: "Escáner QR",
      show_qr_scanner: "Mostrar escáner QR",
      hide_qr_scanner: "Ocultar escáner QR",
      check_qr: "Comprobar QR",
      booking_found: "Reserva encontrada",
      Pending: "Pendiente",
      Redeemed: "Canjeado",
      Cancelled: "Cancelado",
      Paid: "Pagado",
      no_redeemed: "no canjeado",
      booking_not_found: "Reserva no encontrada",
      booking_not_found_text: "No se ha encontrado ninguna reserva con ese código QR",
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "es", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
