import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  Navigate,
} from "react-router-dom";
import Panel from "@main/panel-components/Panel";
import Login from "@beeldit/user-and-access/auth/pages/Login";
import { AuthProvider } from "@beeldit/user-and-access/auth/AuthContext";
import React from "react";
import TenantsPage from "@main/tenants/pages/TenantsPage";
import EventsPage from "@main/events/pages/EventsPage";
import { ListActionProvider as EventListActionProvider } from "./events/actions/list/ListActionContext";
import { CreateActionProvider as EventCreateActionProvider } from "./events/actions/create/CreateActionContext";
import { RemoveActionProvider as EventRemoveActionProvider } from "./events/actions/remove/RemoveActionContext";
import { EditActionProvider as EventEditActionProvider } from "./events/actions/edit/EditActionContext";
import { DetailsActionProvider as EventDetailsActionProvider } from "./events/actions/details/DetailsActionContext";
import EventRulePricePage from "@main/modules/event-rule-prices/pages/EventRulePricePage";
import { EventRulePriceProvider } from "@main/modules/event-rule-prices/contexts/EventRulePriceContext";
import { ListActionProvider as TenantListActionProvider } from "./tenants/actions/list/ListActionContext";
import { CreateActionProvider as TenantCreateActionProvider } from "./tenants/actions/create/CreateActionContext";
import { RemoveActionProvider as TenantRemoveActionProvider } from "./tenants/actions/remove/RemoveActionContext";
import { EditActionProvider as TenantEditActionProvider } from "./tenants/actions/edit/EditActionContext";
import { ListActionProvider as CommerceListActionProvider } from "./commerces/actions/list/ListActionContext";
import { CreateActionProvider as CommerceCreateActionProvider } from "./commerces/actions/create/CreateActionContext";
import { RemoveActionProvider as CommerceRemoveActionProvider } from "./commerces/actions/remove/RemoveActionContext";
import { EditActionProvider as CommerceEditActionProvider } from "./commerces/actions/edit/EditActionContext";
import { DetailsActionProvider as CommerceDetailsActionProvider } from "./commerces/actions/details/DetailsActionContext";
import CommercePage from "./commerces/pages/CommerceDashboardPage";
import CommercesPage from "./commerces/pages/CommercesPage";
import { TenantAndCommerceProvider } from "./TenantAndCommerceContext";
import EventDashboardPage from "./events/pages/EventDashboardPage";
import { GenerateEventSessionsProvider } from "./events/actions/generate-event-sessions/GenerateEventSessionsContext";

import HeaderDashboardPage from "@bloonde/cms/cms-headers/pages/HeaderDashboardPage";
import { DetailsActionProvider as HeaderDetailsActionProvider } from "@bloonde/cms/cms-headers/actions/details/DetailsActionContext";
import FooterDashboardPage from "@bloonde/cms/cms-footers/pages/FooterDashboardPage";
import { DetailsActionProvider as FooterDetailsActionProvider } from "@bloonde/cms/cms-footers/actions/details/DetailsActionContext";
import { ListActionProvider as PageListActionProvider } from "@bloonde/cms/cms-pages/actions/list/ListActionContext";
import { CreateActionProvider as PageCreateActionProvider } from "@bloonde/cms/cms-pages/actions/create/CreateActionContext";
import { RemoveActionProvider as PageRemoveActionProvider } from "@bloonde/cms/cms-pages/actions/remove/RemoveActionContext";
import PagesPage from "@bloonde/cms/cms-pages/pages/PagesPage";

import QRScannerDashboardPage from "@main/qr-scanner/pages/QRScannerDashboardPage";
import { DetailsActionProvider as QRScannerDetailsActionProvider } from "@main/qr-scanner/actions/details/DetailsActionContext";

import { DetailsActionProvider as PageDetailsActionProvider } from "@bloonde/cms/cms-pages/actions/details/DetailsActionContext";
import PageEditionPage from "@bloonde/cms/cms-pages/pages/PageEditionPage";
import { ConfigurationsProvider } from "./contexts/ConfigurationsContext";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <AuthProvider>
        <ConfigurationsProvider>
          <div className="App">
            <Outlet />
          </div>
        </ConfigurationsProvider>
      </AuthProvider>
    ),
    children: [
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/",
        index: true,
        element: <Navigate to="/panel/1/1/events" />,
      },
      {
        path: "/panel/:tenantId/:commerceId",
        element: (
          <TenantAndCommerceProvider>
            <Panel />
          </TenantAndCommerceProvider>
        ),
        children: [
          {
            path: "events",
            element: (
              <GenerateEventSessionsProvider>
                <EventListActionProvider>
                  <EventRemoveActionProvider>
                    <EventCreateActionProvider>
                      <EventEditActionProvider>
                        <EventsPage />
                      </EventEditActionProvider>
                    </EventCreateActionProvider>
                  </EventRemoveActionProvider>
                </EventListActionProvider>
              </GenerateEventSessionsProvider>
            ),
          },
          {
            path: "events/:eventId",
            element: (
              <EventDetailsActionProvider>
                <EventDashboardPage />
              </EventDetailsActionProvider>
            ),
          },
          {
            path: "event-rule-price",
            element: (
              <EventRulePriceProvider>
                <EventRulePricePage />
              </EventRulePriceProvider>
            ),
          },
          {
            path: "header",
            element: (
              <HeaderDetailsActionProvider>
                <HeaderDashboardPage />
              </HeaderDetailsActionProvider>
            ),
          },
          {
            path: "footer",
            element: (
              <FooterDetailsActionProvider>
                <FooterDashboardPage />
              </FooterDetailsActionProvider>
            ),
          },
          {
            path: "qr-scanner",
            element: (
              <QRScannerDetailsActionProvider>
                <QRScannerDashboardPage />
              </QRScannerDetailsActionProvider>
            ),
          },
          {
            path: "pages",
            element: (
              <PageListActionProvider
                initialUrlFragment="1/1/pages"
                key="pages"
              >
                <PageRemoveActionProvider>
                  <PageCreateActionProvider>
                    <PagesPage
                      config={{ showCreation: false }}
                      filters={{ type: 1 }}
                    />
                  </PageCreateActionProvider>
                </PageRemoveActionProvider>
              </PageListActionProvider>
            ),
          },
          {
            path: "pages/:pageId",
            element: (
              <PageDetailsActionProvider>
                <PageEditionPage />
              </PageDetailsActionProvider>
            ),
          },
        ],
      },
      {
        path: "/panel/:tenantId",
        element: (
          <TenantAndCommerceProvider>
            <Panel />
          </TenantAndCommerceProvider>
        ),
        children: [
          // {
          //   path: "commerces",
          //   element: (
          //     <CommerceListActionProvider>
          //       <CommerceRemoveActionProvider>
          //         <CommerceCreateActionProvider>
          //           <CommerceEditActionProvider>
          //             <CommercesPage />
          //           </CommerceEditActionProvider>
          //         </CommerceCreateActionProvider>
          //       </CommerceRemoveActionProvider>
          //     </CommerceListActionProvider>
          //   ),
          // },
          {
            path: "commerces/:commerceId",
            element: (
              <CommerceDetailsActionProvider>
                <CommercePage />
              </CommerceDetailsActionProvider>
            ),
          },
        ],
      },
      {
        path: "/panel",
        element: (
          <TenantAndCommerceProvider>
            <Panel />
          </TenantAndCommerceProvider>
        ),
        children: [
          // {
          //   path: "tenants",
          //   element: (
          //     <TenantListActionProvider>
          //       <TenantRemoveActionProvider>
          //         <TenantCreateActionProvider>
          //           <TenantEditActionProvider>
          //             <TenantsPage />
          //           </TenantEditActionProvider>
          //         </TenantCreateActionProvider>
          //       </TenantRemoveActionProvider>
          //     </TenantListActionProvider>
          //   ),
          // },
        ],
      },
    ],
  },
]);

export function Router() {
  return <RouterProvider router={router} />;
}
