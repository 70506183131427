import { RJSFSchema, UiSchema } from "@rjsf/utils";
import { customizeValidator } from "@rjsf/validator-ajv8";
import JSONSchemaForm from "@rjsf/core";
import { useTranslation } from "react-i18next";
import { EventContext } from "../contexts/EventContext";
import { MouseEventHandler, useContext, useEffect, useState } from "react";
import customFormats from "@beeldit/core/custom-validations/custom-validation";
import useProcessSchema from "@beeldit/core/hooks/useProcessSchema";
import widgets from "@beeldit/core/widgets/widgets";
import CommerceSelector from "@main/commerces/components/CommerceSelector";
import EventTypeSelector from "@main/event-types/components/EventTypeSelector";
import RoomSelector from "@main/rooms/components/RoomSelector";

interface Prop {
  formConfig: {
    formRef: any;
    onSubmit: any;
    context: any;
  };
}

interface Context {
  element: any;
  setElement: (element: any) => MouseEventHandler;
  backendFormErrors: any;
}

function EventForm(props: Prop) {
  const { formRef, onSubmit, context } = props.formConfig;
  const { element, setElement, backendFormErrors } =
    useContext<Context>(context);

  const { t } = useTranslation();

  const [finalSchema, setFinalSchema] = useState<RJSFSchema>({});

  const translations = {
    name: t("name"),
    description: t("description"),
    capacity: t("capacity"),
    room_id: t("room"),
    commerce_id: t("commerce"),
    event_type_id: t("event_type"),
    date_start: t("date_end"),
    date_end: t("date_end"),
  };

  let schema: RJSFSchema = {
    type: "object",
    // required: ['minutes', 'customer_id', 'task', 'date', 'report_id'],
    properties: {      
      room_id: {
        type: "integer",
        title: translations.room_id,
      },
      event_type_id: {
        type: "integer",
        title: translations.event_type_id,
      },
      name: {
        type: "string",
        title: translations.name,
      },
      description: {
        type: "string",
        title: translations.description,
      },
      capacity: {
        type: "number",
        minimum: 0,
        title: translations.capacity,
      },
      date_start: {
        type: "string",
        title: translations.date_start,
        format: "date",
        default: new Date().toISOString().slice(0, 10),
      },
      date_end: {
        type: "string",
        title: translations.date_end,
        format: "date",
        default: new Date().toISOString().slice(0, 10),
      },
    },
  };
  const uiSchema: UiSchema = {
    "ui:submitButtonOptions": {
      props: {
        disabled: false,
        className: "btn btn-info",
      },
      norender: true,
    },    
    room_id: {
      "ui:widget": "room-selector",
    },
    event_type_id: {
      "ui:widget": "event-type-selector",
    },
  };
  const customWidgets = {
    ...widgets,
    ...{ "room-selector": RoomSelector },
    ...{ "event-type-selector": EventTypeSelector },
  };
  const handleChange = (type: any) => {
    /** Este método de momento es necesario aunque en realidad no tenga sentido, pero se debe a un bug de la libreria */
    // Actualizar el estado solo si no hay errores de validación
    setElement(type.formData);
    return console.log.bind(console, type);
  };
  const log = (type: any) => {
    return console.log.bind(console, type);
  };

  const formats = customFormats;
  const validator = customizeValidator({ customFormats: formats });

  const processSchemaFunction = useProcessSchema();

  useEffect(() => {
    setFinalSchema(processSchemaFunction(schema, element));
  }, [element]);

  return (
    <JSONSchemaForm
      noHtml5Validate
      showErrorList={false}
      ref={formRef}
      schema={schema}
      uiSchema={uiSchema}
      widgets={customWidgets}
      formData={element}
      validator={validator}
      onChange={handleChange}
      onSubmit={onSubmit}
      onError={log("errors")}
      extraErrors={backendFormErrors}
    />
  );
}

export default EventForm;
