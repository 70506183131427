import { ObjectFieldTemplateProps } from "@rjsf/utils";

function YouWillProbablyLikeTemplate(props: ObjectFieldTemplateProps, func: any) {
  return (
    <>
      <div className="cms-section">
        <h3>{props.title}</h3>
        <div className="section row">
          <div className="col-12">
            <div className="property-wrapper">
              {func("you_will_probably_like", props)?.content}
            </div>
          </div>
          <div className="col-12">
            <div className="property-wrapper">
              {func("bear_in_mind", props)?.content}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default YouWillProbablyLikeTemplate;
