import React, { createContext, useState } from "react";

interface ConfigurationsContextType {
  langs: string[];
  setLangs: (langs: string[]) => void;
  schemas: {};
  setSchemas: (schemas: any) => void;
}

export const ConfigurationsContext = createContext<ConfigurationsContextType>({
  langs: [],
  setLangs: () => {},  
  schemas: {},
  setSchemas: () => {}
});



interface ConfigurationsProviderProps {
  children: React.ReactNode;
}

export const ConfigurationsProvider: React.FC<ConfigurationsProviderProps> = ({
  children,
}) => {
  const [langs, setLangs] = useState<string[]>([]);
  const [schemas, setSchemas] = useState({});

  const value = {
    langs,
    setLangs,
    schemas,
    setSchemas    
  };
  return (
    <ConfigurationsContext.Provider value={value}>
      {children}
    </ConfigurationsContext.Provider>
  );
};
