import { createRef, useContext } from "react";
import JSONSchemaForm from "@rjsf/core";
import CrudModuleFactoryModal from "@beeldit/core/components/CrudModuleFactoryModal";
import useInitialize from "./useInitialize";
import useStore from "./useStore";
import { CreateActionContext } from "./CreateActionContext";
import PageForm from "@bloonde/cms/cms-pages/components/PageForm";

function useCreateAction(index: any, formDefaultValues: any = {}): any {
  const initialize = useInitialize();
  const initializeFunction = () => initialize(formDefaultValues);

  /**
   * Form store success function definition
   */
  const onSuccessStore = () => {
    setShowModal(false);
    index(); // We pass the function to refresh the table on success
  };

  const store = useStore();
  const storeFunction = () => store(onSuccessStore);

  /** Creation and edition modal function and state definition */
  const { setShowModal } = useContext(CreateActionContext);

  /** Creation and edition modal element definition */
  const { element } = useContext(CreateActionContext);

  /**
   * Form Reference definition
   */
  const formRef = createRef<JSONSchemaForm>();

  /**
   * Creation and edition modal configuration definition
   */
  const modalConfig = {
    title: "create_page",
    context: CreateActionContext,
    onSubmit: () => formRef.current?.formElement.current.requestSubmit(), //We pass the function for the submit of the modal
    size: "modal-lg",
  };

  /**
   * Form configuration definition
   */
  const formConfig = {
    formRef: formRef, // We pass the ref to the form
    onSubmit: () => {
      storeFunction();
    },
    element: element, // We pass the element to the form in order to render it in the modal form
    context: CreateActionContext,
    className: "page-form"
  };

  const actionTemplate = (
    <CrudModuleFactoryModal modalConfig={modalConfig}>
      <PageForm formConfig={formConfig} />
    </CrudModuleFactoryModal>
  );

  /** END EDIT FORM ACTION */

  return {
    initializeFunction,
    actionTemplate,
  };
}

export default useCreateAction;
